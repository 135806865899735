@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.contentWrapper {
  display: grid;
  grid-template-columns: max-content max-content;

  &:not(:last-child) {
    padding-bottom: 16px;
  }

  & span {
    @include font-style-tiny($font-weight-bold);

    grid-column: 1/-1;

    color: var(--text-20);
    text-transform: uppercase;
  }

  & p {
    margin-right: 14px;
  }
}

.userFields {
  @include font-style-default;
  color: var(--text-10);
  margin: 0;
  cursor: text;
}

.oldPasswordModalHeader {
  margin-bottom: 4px;
}

.oldPasswordField {
  max-width: 100%;
}

.newPasswordFieldsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 20px;
  padding-bottom: 8px;
  border-top: 1px solid var(--shape-50);
}

.newPasswordFieldWrapper {
  width: 240px;
}

.modalRequestError {
  margin-top: 30px;
  border: 1px solid var(--system-error);
  border-radius: 4px;
  padding: 8px;
  white-space: pre-line;

  & .modalErrorText {
    @include font-style-small;
    color: var(--system-error);
    margin: 0;
  }
}

.modalFooter {
  & .changePasswordBtn {
    min-width: 111px;
    display: flex;
    justify-content: center;

    & span svg {
      width: 16px;
    }
  }
}
